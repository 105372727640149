/* global.css */

/* Grundlegende Stile für das dunkle Design */
body {
    background-color: #121212;
    color: #e0e0e0;
    font-family: 'Arial', sans-serif;
    margin: 0;
    padding: 0;
}

/* Links */
a {
    color: #bb86fc;
    text-decoration: none;
}

a:hover {
    text-decoration: underline;
}

/* Überschriften */
h1, h2, h3, h4, h5, h6 {
    color: #ffffff;
}

/* Eingabefelder */
input, textarea {
    background-color: #1e1e1e;
    color: #e0e0e0;
    border: 1px solid #333333;
    padding: 10px;
    margin-bottom: 15px;
    width: 100%;
    box-sizing: border-box;
}

/* Buttons */
button {
    background-color: #333333;
    color: #e0e0e0;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
}

button:hover {
    background-color: #444444;
}

/* Container */
.container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
}

/* Karten oder Abschnitte */
.section {
    background-color: #1e1e1e;
    padding: 20px;
    margin-bottom: 20px;
    border-radius: 5px;
}

/* Scrollbars */
::-webkit-scrollbar {
    width: 8px;
}

::-webkit-scrollbar-track {
    background: #121212;
}

::-webkit-scrollbar-thumb {
    background: #333333;
    border-radius: 4px;
}

::-webkit-scrollbar-thumb:hover {
    background: #444444;
}
/* Bildvorschau */
.image-preview {
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
}

.image-item {
    position: relative;
}

.preview-image {
    max-width: 600px;
    max-height: 600px;
    border: 1px solid #333333;
}

.image-item button {
    position: absolute;
    top: 5px;
    right: 5px;
    background-color: #ff1744;
    color: #ffffff;
    border: none;
    padding: 5px;
    cursor: pointer;
}

.image-item button:hover {
    background-color: #ff4569;
}
/* Für mobile Geräte */
@media (max-width: 600px) {
    .container {
        padding: 10px;
    }

    .image-preview {
        flex-direction: column;
        align-items: center;
    }

    .preview-image {
        max-width: 100%;
    }
}
