/* src/components/EntryManagementPage.css */

.entry-management {
    padding: 20px;
    transition: font-size 0.3s ease; /* Übergangseffekt für die Textgröße */
}

.entries-list h2 {
    cursor: pointer;
    user-select: none;
}

.entry-details {
    padding-left: 20px;
}

.entry-actions {
    margin-bottom: 10px;
}

.entry-actions button {
    margin-right: 10px; /* Abstand zwischen den Buttons */
    margin-bottom: 10px; /* Abstand nach unten */
}

.entry-images {
    display: flex;
    flex-wrap: wrap;
}

.image-item {
    margin-right: 10px;
    margin-bottom: 10px;
}

.image-item img {
    max-width: 150px;
    cursor: pointer;
}

.image-item button {
    background: none;
    border: none;
    cursor: pointer;
    color: #e0e0e0;
    font-size: 18px;
    margin-top: 5px;
}

.image-item button:hover {
    color: #ffffff;
}

.image-item button .svg-inline--fa {
    vertical-align: middle;
}

/* Modal Styles */
.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Abdunkelung des Hintergrunds */
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
}

/* Hintergrundfarbe des Modals auf Grau setzen */
.modal-content {
    background-color: #121212; /* Helles Grau */
    padding: 20px;
    max-height: none;
    overflow-y: auto;
    min-width: 300px;
    min-height: 200px;
    max-width: none;
    max-height: none;
    position: relative;
    resize: both;
    overflow: auto;
}

/* Textfarbe anpassen für bessere Lesbarkeit auf grauem Hintergrund */
.modal-content h2,
.modal-content label,
.modal-content .custom-checkbox {
    color: #fff; /* Weißer Text */
}

.modal-content h2 {
    margin-top: 0;
    color: #fff; /* Weißer Text */
}

.modal-content label {
    display: block;
    margin-top: 15px;
    font-weight: bold;
    color: #fff; /* Weißer Text */
}

.modal-content input[type="text"] {
    width: 100%;
    padding: 8px;
    margin-top: 5px;
    box-sizing: border-box;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.modal-content .button-group {
    margin-top: 20px;
    display: flex;
    justify-content: flex-end;
}

.modal-content .button-group button {
    margin-left: 10px;
}

/* Schaltflächenstil */
button {
    background-color: #007BFF; /* Blaues Standarddesign */
    color: #fff;
    border: none;
    padding: 10px 15px;
    font-size: 16px;
    border-radius: 4px;
    cursor: pointer;
}

button:hover {
    background-color: #0056b3; /* Dunkleres Blau beim Hover */
}

button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
}

/* Für das Schließen-Button eine alternative Farbe */
button.close-button {
    background-color: #252525;
}

button.close-button:hover {
    background-color: #d80000;
}

/* Abstände und Layout in der Modal */
.section {
    margin-top: 20px;
}

/* Styles für die Dropdowns (Kategorien) */
.section h2,
.section h3 {
    cursor: pointer;
    user-select: none;
    color: #fff; /* Weißer Text */
}

.section h2:hover,
.section h3:hover {
    text-decoration: underline;
}

.subcategory,
.checkbox-wrapper {
    margin-left: 20px;
}

.custom-checkbox {
    position: relative;
    display: inline-block;
    font-size: 16px;
    color: #fff; /* Weißer Text */
}

.custom-checkbox input[type="checkbox"] {
    position: absolute;
    opacity: 0;
    cursor: pointer;
}

.custom-checkbox .checkmark {
    position: relative;
    top: 0;
    left: 0;
    height: 18px;
    width: 18px;
    background-color: transparent;
    border: 2px solid #fff;
    border-radius: 4px;
    margin-right: 8px;
    display: inline-block;
}

.custom-checkbox input[type="checkbox"]:checked ~ .checkmark {
    background-color: #6A0DAD; /* Violette Farbe */
    border-color: #6A0DAD;
}

.custom-checkbox .checkmark::after {
    content: "";
    position: absolute;
    display: none;
}

.custom-checkbox input[type="checkbox"]:checked ~ .checkmark::after {
    display: block;
}

.custom-checkbox .checkmark::after {
    left: 5px;
    top: 1px;
    width: 4px;
    height: 8px;
    border: solid #fff;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
}

/* Responsive Anpassungen */
@media (max-width: 600px) {
    .modal-content {
        width: 90%;
        padding: 15px;
    }

    .modal-content h2 {
        font-size: 20px;
    }

    .custom-checkbox {
        font-size: 14px;
    }
}

/* Restliche Styles ... */

/* Box-Stil für Notizen */
.entry-notes-box {
    background-color: #1e1e1e;
    padding: 15px;
    border-radius: 5px;
    margin-bottom: 20px;
}

.entry-notes-box h3 {
    margin-top: 0;
}

.controls-container {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}

.text-size-controls {
    display: flex;
    align-items: center;
    margin-right: 20px; /* Abstand zwischen Slider und Filter-Button */
}

.text-size-controls span {
    font-size: 16px;
    color: #fff;
    margin-right: 10px;
}

.text-size-controls .MuiSlider-root {
    color: #90caf9; /* Farbe des Sliders an das Theme anpassen */
    width: 200px;
}

.resize-handle {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 20px;
    height: 20px;
    cursor: se-resize;
    background: linear-gradient(135deg, transparent 50%, #666 50%);
}

.modal-content:hover .resize-handle {
    opacity: 1;
}