/* ImageUploader.css */

/* Verstecke das Standard-File-Input */
.custom-file-upload input[type="file"] {
    display: none;
}

/* Style für das Label, das als Button fungiert */
.custom-file-upload label {
    display: inline-block;
    padding: 10px 20px;
    background-color: #333333;
    color: #e0e0e0;
    cursor: pointer;
    border: 1px solid #444444;
    border-radius: 4px;
    margin: 10px 0px;
}

.custom-file-upload label:hover {
    background-color: #444444;
}

/* Styling für die Bildvorschau */
.image-preview {
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
}

.image-item {
    position: relative;
    margin-bottom: 20px;
}

.preview-image {
    width: 200px;
    height: auto;
    border: 1px solid #333333;
}

.image-filename {
    text-align: center;
    margin-top: 5px;
}

/* Zentrierung der Buttons in .image-buttons */
.image-buttons {
    display: flex;
    justify-content: center;
    margin-top: 5px;
}

/* Anpassung der Buttons */
.image-buttons button {
    background: none;
    border: none;
    cursor: pointer;
    color: #e0e0e0;
    font-size: 18px;
}

/* Anpassung der Hover-Farbe */
.image-buttons button:hover {
    color: #ffffff;
}

/* Optional: Icons zentrieren */
.image-buttons button .svg-inline--fa {
    vertical-align: middle;
}