/* DynamicCheckboxes.css */

.section {
    font-family: Arial, sans-serif;
    color: #e0e0e0;
}

.category {
    margin-left: 0;
}

.category-header {
    font-size: 1.2em;
    cursor: pointer;
}

.subcategory {
    margin-left: 20px; /* Einrückung für Unterkategorien */
}

.subcategory-header {
    font-size: 1em; /* Kleinere Schriftgröße für Unterkategorien */
    cursor: pointer;
}

.option {
    margin-left: 40px; /* Weitere Einrückung für Optionen */
    font-size: 0.9em; /* Noch kleinere Schriftgröße für Optionen */
}

/* Benutzerspezifische Checkbox-Stile */
.custom-checkbox {
    display: inline-flex;          /* Verwenden von inline-flex */
    align-items: center;           /* Vertikale Zentrierung */
    font-size: 16px;
    color: #fff;                   /* Weißer Text */
    line-height: 1;                /* Zeilenhöhe auf 1 setzen */
    margin-top: 5px;               /* Falls erforderlich, anpassen oder entfernen */
}

/* Verstecke das Standard-Checkbox-Kontrollkästchen */
.custom-checkbox input[type="checkbox"] {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

/* Erstelle ein benutzerdefiniertes Kontrollkästchen */
.custom-checkbox .checkmark {
    display: flex;                 /* Flexbox für die Checkmark */
    align-items: center;           /* Vertikale Zentrierung */
    justify-content: center;       /* Horizontale Zentrierung */
    position: relative;
    height: 18px;
    width: 18px;
    background-color: transparent;
    border: 2px solid #fff;
    border-radius: 4px;
    margin-right: 8px;
    box-sizing: border-box;        /* Um Padding und Border einzubeziehen */
}

/* Bei Hover */
.custom-checkbox:hover .checkmark {
    background-color: #333333;
}

/* Wenn das Kontrollkästchen aktiviert ist */
.custom-checkbox input:checked ~ .checkmark {
    background-color: #6A0DAD; /* Violette Farbe */
    border-color: #6A0DAD;
}

/* Checkmark/Indicator */
.custom-checkbox .checkmark::after {
    content: "";
    position: absolute;
    display: none;
    width: 4px;
    height: 8px;
    border: solid #fff;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
}

/* Zeige das Symbol, wenn aktiviert */
.custom-checkbox input:checked ~ .checkmark::after {
    display: block;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%) rotate(45deg);
}

/* Optional: Text innerhalb der Checkbox zentrieren */
.custom-checkbox .checkbox-label {
    vertical-align: center;
}
