.category-management-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
}

h1 {
    text-align: center;
    margin-bottom: 20px;
}

.category-header, .subcategory-header {
    display: flex;
    align-items: center;
}

.toggle-icon {
    cursor: pointer;
    margin-right: 10px;
}

.input-group {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    flex: 1;
}

.input-group input {
    flex: 1;
    padding: 8px;
}

.input-group .delete-button {
    margin-left: 10px;
}

.category-item, .subcategory-item, .option-item {
    border: 1px solid #ccc;
    padding: 10px;
    margin-bottom: 10px;
    border-radius: 5px;
    transition: border 0.2s ease, background-color 0.2s ease;
}

.drag-handle {
    cursor: move;
    margin-right: 10px;
    display: flex;
    align-items: center;
}

.category-item.dragging,
.subcategory-item.dragging,
.option-item.dragging {
    opacity: 0.5;
    border-color: #007bff;
}

.subcategory-item {
    margin-left: 20px;
}

.option-item {
    margin-left: 40px;
}

.add-button, .save-button, .delete-button {
    background-color: #007bff;
    color: #fff;
    border: none;
    padding: 8px 12px;
    cursor: pointer;
    border-radius: 5px;
    font-size: 14px;
}

.add-button {
    margin-bottom: 10px;
}

.sub-add-button {
    margin-left: 20px;
}

.option-add-button {
    margin-left: 40px;
}

.save-button {
    position: fixed;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    width: 200px;
    font-size: 16px;
    z-index: 1000;
    background-color: #007bff;
    color: #fff;
    border: none;
    padding: 8px 12px;
    cursor: pointer;
    border-radius: 5px;
    transition: background-color 0.3s ease, transform 0.3s ease;
}

.save-button:hover {
    background-color: #0056b3;
    transform: translateX(-50%) scale(1.05);
}

.delete-button {
    background-color: #dc3545;
}

.add-button:hover, .save-button:hover, .delete-button:hover {
    opacity: 0.9;
}

.category-item.hovered,
.subcategory-item.hovered,
.option-item.hovered {
    border: 2px dashed #007bff;
}
